<ion-content #scrollArea scrollEvents="true" class="light-background controls-section">
    <div class="d-flex flex-column h-100">
        <div style="position: sticky; top: 0; flex: 0 1 auto; z-index: 1;">
            <div class="p-3 bg-white d-flex flex-row flex-wrap">
                <ng-container *ngIf="displayUser && displayUser.firstName">
                    <div class="fw-bold header-font btn-header m-0 w-100 mb-2">
                        {{ 'Hello, ' + displayUser.firstName }}
                    </div>
                </ng-container>
                <div class="d-flex flex-row flex-wrap align-items-end w-100 mb-2">
                    <div class="search-label m-0 grow-1">
                        Search for a Pickup Location
                    </div>
                    <ng-container *ngIf="locations.userCoords">
                        <button type="button" [class.map-bg]="!runToggleAnimation" [class.list-bg]="runToggleAnimation"
                                class="d-flex align-items-center m-0 p-1 toggle-switch border" (click)="switchView()">
                            <div class="d-flex justify-content-center align-items-center toggle-circle"
                                 [class.map-toggle]="runToggleAnimation" [class.list-toggle]="!runToggleAnimation">
                                <i [class.bi-map]="showListView" [class.bi-view-list]="!showListView" class="bi"></i>
                            </div>
                        </button>
                    </ng-container>
                </div>
                <app-locations-search class="w-100"
                                      [guestDeliveryAddress]="guestDeliveryAddress">
                </app-locations-search>
            </div>
        </div>

        <div style="flex: 0 1 1px;">
            <div class="divider"></div>
        </div>

        <div id="locations-list-wrap" style="flex: 1 1 auto; overflow: auto; display: contents;">

            <ion-grid class="locations-list bg-light list-section m-0 w-100" *ngIf="showListView">
                <ng-container *ngIf="!locations.findingLocations else findingLocationsText">

                    <ng-container *ngIf="locations.isLoading === false; else loadingLocations">
                        <ng-container *ngIf="sortedLocations && sortedLocations.length > 0; else emptyList">
                            <div class="d-flex flex-row flex-wrap p-3 pointer">

                                <ng-container *ngFor="let location of sortedLocations; first as first; last as last">
                                    <ng-container *ngIf="!last">
                                        <app-mobile-location-card [locationID]="location.locationID"
                                                                  [isCurrentLocation]="selectedLocation && location.locationID === selectedLocation.locationID"
                                                                  (orderNowButtonClick)="orderNowButtonClicked($event)"
                                                                  (locationInfoButtonClick)="locationInfoButtonClicked($event)"
                                                                  pageFrom="locations"
                                                                  [editHandoff]="editHandoff"
                                                                  *ngIf="location.isPrivate === false"
                                                                  [locationLoading]="locations.locationLoading"
                                                                  [cardEmphasis]="cardEmphasis"
                                                                  class="w-100 mb-3">
                                        </app-mobile-location-card>
                                    </ng-container>
                                    <ng-container *ngIf="last">
                                        <app-mobile-location-card [locationID]="location.locationID"
                                                                  [isCurrentLocation]="selectedLocation && location.locationID === selectedLocation.locationID"
                                                                  (orderNowButtonClick)="orderNowButtonClicked($event)"
                                                                  (locationInfoButtonClick)="locationInfoButtonClicked($event)"
                                                                  pageFrom="locations"
                                                                  [editHandoff]="editHandoff"
                                                                  *ngIf="location.isPrivate === false"
                                                                  [locationLoading]="locations.locationLoading"
                                                                  [cardEmphasis]="cardEmphasis"
                                                                  class="w-100">
                                        </app-mobile-location-card>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ion-grid>

            <app-simple-map *ngIf="!showListView" style="height: 100%; width: 100vw; display: inline-block;"
                            [centerLat]="centerLat" [centerLong]="centerLong" [zoom]="zoom" [markers]="markers"
                            [apiKey]="apiKey"
                            (locationInfoButtonClick)="locationInfoButtonClicked($event)"
                            (orderNowButtonClick)="orderNowButtonClicked($event)" [lat]="lat" [lng]="lng">
            </app-simple-map>

        </div>

    </div>

</ion-content>

<ng-template #loadingLocations>
    <div class='de-btn-loader-secondary my-5'></div>
</ng-template>

<ng-template #findingLocationsText>
    <div class="w-100 p-3">
        <div class="w-100 border bg-white text-center p-2 de-btn-radius">Finding Locations...</div>
    </div>
</ng-template>

<ng-template #noLocations>
    <div class="alert alert-info text-center de-btn-radius">
        {{ locations.infoMessage }}
    </div>
</ng-template>

<ng-template #emptyList>
    <section class="empty-list-container h-100">
        <div class="empty-list">
            <ng-container *ngIf="branding.favicon">
                <img alt="Empty Location List Image" class="empty-list-image"
                     [src]="branding.favicon.data.full_url"/>
            </ng-container>
            <h3 class="list-head fw-bold m-0 header-font text-center">Use Location Search</h3>
            <p class="text-center mb-2">{{locations.infoMessage}}</p>
        </div>
    </section>
</ng-template>
