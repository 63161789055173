import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { from } from 'rxjs';
import { Preferences as Storage } from '@capacitor/preferences';
import { LocalStorageKey } from '../../../models/common.enum';
import { catchError, map } from 'rxjs/operators';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class TokenManagerService {
  constructor() {}

  clearProviderTokens(): Observable<void> {
    return forkJoin({
      novadineUserID: from(Storage.remove({ key: LocalStorageKey.NOVADINE_USER_ID })),
      oloAccessToken: from(Storage.remove({ key: LocalStorageKey.OLO_ACCESS_TOKEN })),
      oloAuthToken: from(Storage.remove({ key: LocalStorageKey.OLO_AUTH_TOKEN })),
      punchhAuthToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_AUTH_TOKEN })),
      punchhAccessToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_ACCESS_TOKEN })),
      punchhMobileAuthToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_MOBILE_AUTH_TOKEN })),
      punchhMobileAccessToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_MOBILE_ACCESS_TOKEN })),
      punchhOauthJwtToken: from(Storage.remove({ key: LocalStorageKey.PUNCHH_OAUTH_JWT_TOKEN })),
      punchhOauthCode: from(Storage.remove({ key: LocalStorageKey.PUNCHH_OAUTH_CODE })),
      punchhAppleUserData: from(Storage.remove({ key: LocalStorageKey.PUNCHH_APPLE_USER_DATA })),
      paytronixAuthToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_AUTH_TOKEN })),
      paytronixAccessToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_ACCESS_TOKEN })),
      paytronixRefreshToken: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_REFRESH_TOKEN })),
      paytronixRefreshTokenExpiration: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_REFRESH_TOKEN_EXPIRATION })),
      paytronixPrintedCardNumber: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_PRINTED_CARD_NUMBER })),
      paytronixUsername: from(Storage.remove({ key: LocalStorageKey.PAYTRONIX_USERNAME })),
      personicaAccessToken: from(Storage.remove({ key: LocalStorageKey.PERSONICA_ACCESS_TOKEN })),
      personicaMemberID: from(Storage.remove({ key: LocalStorageKey.PERSONICA_MEMBER_ID })),
      personicaAccessTokenExpiration: from(Storage.remove({ key: LocalStorageKey.PERSONICA_ACCESS_TOKEN_EXPIRATION })),
      spendgoMemberID: from(Storage.remove({ key: LocalStorageKey.SPENDGO_MEMBER_ID })),
      spendgoAccessToken: from(Storage.remove({ key: LocalStorageKey.SPENDGO_ACCESS_TOKEN })),
      spendgoAccessTokenExpiration: from(Storage.remove({ key: LocalStorageKey.SPENDGO_ACCESS_TOKEN_EXPIRATION })),
      spendgoRefreshToken: from(Storage.remove({ key: LocalStorageKey.SPENDGO_REFRESH_TOKEN })),
      spendgoRefreshTokenExpiration: from(Storage.remove({ key: LocalStorageKey.SPENDGO_REFRESH_TOKEN_EXPIRATION })),
      // authUsername:
      //   Capacitor.getPlatform() === 'web'
      //     ? of(null)
      //     : from(SecureStoragePlugin.remove({ key: 'auth.username' })).pipe(catchError(() => of(null))),
      // authPassword:
      //   Capacitor.getPlatform() === 'web'
      //     ? of(null)
      //     : from(SecureStoragePlugin.remove({ key: 'auth.password' })).pipe(catchError(() => of(null))),
      // authBiometric:
      //   Capacitor.getPlatform() === 'web'
      //     ? of(null)
      //     : from(SecureStoragePlugin.remove({ key: 'auth.biometrics' })).pipe(catchError(() => of(null))),
      // authRememberMe:
      //   Capacitor.getPlatform() === 'web'
      //     ? of(null)
      //     : from(SecureStoragePlugin.remove({ key: 'auth.remember' })).pipe(catchError(() => of(null))),
    }).pipe(map(() => {}));
  }
}
