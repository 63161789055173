import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {Location as DineEngineLocation} from '../../../../../interfaces/location.interface';
import {User} from '../../../../../interfaces/user.interface';
import {VendorSetup} from 'src/interfaces/vendor.interface';
import {LocationsService} from '@modules/locations/services';
import {Branding} from '../../../../../vendors/directus/interfaces/branding.interface';

@Component({
  selector: 'app-desktop-location-list',
  templateUrl: './desktop-location-list.component.html',
  styleUrls: ['./desktop-location-list.component.scss']
})
export class DesktopLocationListComponent implements OnChanges {
  @ViewChild('lastLocation', {static: false}) private lastLocation: ElementRef<HTMLDivElement>;

  @Input() sortedLocations: DineEngineLocation[];
  @Input() deliveryLocations: DineEngineLocation[];
  @Input() selectedLocation: DineEngineLocation;
  @Input() showDesktopView: boolean;
  @Input() showListView: boolean;
  @Input() displayUser: User;

  @Input() distances;
  @Input() editHandoff;
  @Input() vendorSetup: VendorSetup;
  @Input() guestDeliveryAddress;
  @Input() cardEmphasis: boolean;
  @Input() branding: Branding;

  @Input() error;

  @Output() orderNowButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() locationInfoButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() deliveryButtonClick = new EventEmitter<any>();
  @Output() pickupButtonClick = new EventEmitter<any>();
  @Output() showAllLocations = new EventEmitter<any>();
  @Output() hoveredIndex = new EventEmitter<number>();
  @Output() leftCard = new EventEmitter<number>();

  allLocationsShowing = false;

  shownLocations: DineEngineLocation[] = [];

  locationsShowing = 20;

  constructor(
    public locations: LocationsService
  ) {
  }

  ngOnChanges() {
    this.shownLocations = this.sortedLocations.slice(0, this.locationsShowing);
  }

  onScroll() {
    if (this.lastLocation) {
      const rect = this.lastLocation.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      if (topShown && bottomShown) {
        this.locationsShowing += 10;
        if (this.locationsShowing < this.sortedLocations.length) {
          this.shownLocations = this.sortedLocations.slice(0, this.locationsShowing);
        }
      }
    }
  }

  orderNowButtonClicked(loc: DineEngineLocation) {
    this.orderNowButtonClick.emit(loc);
  }

  locationInfoButtonClicked(loc: DineEngineLocation) {
    this.locationInfoButtonClick.emit(loc);
  }

  deliveryButtonClicked() {
    this.deliveryButtonClick.emit();
  }

  pickupButtonClicked() {
    this.pickupButtonClick.emit();
  }

  showAllLocationsClicked() {
    this.showAllLocations.emit();
    this.allLocationsShowing = true;
  }

  listCardLeft() {
    this.leftCard.emit(null);
  }

  cardHovered(index: number) {
    this.hoveredIndex.emit(index);
  }

}
