import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HexToRgbCssPipe } from './hex-to-rgb-css.pipe';
import { HexToRgbObjectPipe } from './hex-to-rgb-object.pipe';
import { SvgObjectFilterPipe } from './svg-object-filter.pipe';
import { BootstrapIconPipe } from './bootstrap-icon.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CreditCardTransformPipe } from './credit-card-mask.pipe';
import { DaypartCategoriesPipe } from './daypart-categories.pipe';
import { DaypartProductsPipe } from './daypart-products.pipe';
import { NavLinkPipe } from './nav-link.pipe';
import { OptionGroupFilter } from './optiongroup-filter.pipe';
import { OptionsListPipe } from './options-list.pipe';
import { PhonePipe } from './phone.pipe';
import { SearchPipe } from './search.pipe';
import { SelectedOptionsPipe } from './selected-options.pipe';
import { TextTruncatePipe } from './truncate.pipe';
import { AppliedRewardsFilterPipe } from './applied-rewards-filter.pipe';
import { RadialGradientPipe } from './radial-gradient.pipe';
import { RewardCardBackgroundPipe } from './reward-card-background.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { RewardsBackgroundImagePipe } from './rewards-background-image.pipe';
import { CssUrlPipe } from './css-url.pipe';
import { PurchaseableRewardsSortPipe } from './purchaseable-rewards-sort.pipe';
import { SlugPipe } from '@common/services/slug.pipe';
import { PossessionPipe } from './possession.pipe';
import { BankedRewardFilterPipe } from './banked-reward-filter.pipe';
import { CategoryFromProductPipe } from './category-from-product.pipe';
import { SecretProductsPipe } from './secret-products.pipe';
import { SecretCategoriesPipe } from './secret-categories.pipe';
import { GeoDistancePipe } from './geo-distance.pipe';
import { ProductNotInCartPipe } from './product-not-in-cart.pipe';
import { IsSingleUsePipe } from './is-single-use.pipe';
import { LocationMarkerPipe } from './location-marker.pipe';
import { IsNanPipe } from './is-nan.pipe';
import { UnreadMessagePipe } from './unread-message.pipe';
import { CheckIfUnreadNotificationPipe } from './check-if-unread-notification.pipe';
import { TimeComparisonPipe } from './time-comparison.pipe';
import { TimeSelectShowMoreCheckPipe } from './time-select-show-more-check.pipe';
import { OptionGroupCompletionPipe } from './option-group-completion.pipe';
import { ProductCustomizationCompletionPipe } from './product-customization-completion.pipe';
import { IsOptionValidPipe } from './is-option-valid.pipe';
import { MaxChoiceQuantityPipe } from './max-choice-quantity.pipe';
import { MaxGroupQuantityPipe } from './max-group-quantity.pipe';
import { OptionGroupFullPipe } from './option-group-full.pipe';
import { DedupeAppliedRewardsPipe } from './dedupe-applied-rewards.pipe';
import { FavoriteLocationPickerPipe } from './favorite-location-picker.pipe';
import { SplashscreenBgPipe } from './splashscreen-bg.pipe';
import { GetFormControlFromParentPipe } from './get-form-control-from-parent.pipe';
import { StreetAddressPipe } from './street-address.pipe';
import { DefaultCategoryImagePipe } from './default-category-image.pipe';
import { RemoveCouponRewardsPipe } from './remove-coupon-rewards.pipe';
import { TextColorPipe } from './text-color.pipe';
import { FeaturesFilterPipe } from './features-filter.pipe';
import { TimezoneConvertPipe } from './timezone-convert.pipe';
import { SavedDeliveryAddressFilterPipe } from './saved-delivery-address-filter.pipe';

@NgModule({
  declarations: [
    BootstrapIconPipe,
    CapitalizePipe,
    CreditCardTransformPipe,
    DaypartCategoriesPipe,
    DaypartProductsPipe,
    HexToRgbCssPipe,
    HexToRgbObjectPipe,
    NavLinkPipe,
    OptionGroupFilter,
    OptionsListPipe,
    PhonePipe,
    SearchPipe,
    SelectedOptionsPipe,
    SvgObjectFilterPipe,
    TextTruncatePipe,
    AppliedRewardsFilterPipe,
    RadialGradientPipe,
    RewardCardBackgroundPipe,
    SanitizeHtmlPipe,
    RewardsBackgroundImagePipe,
    CssUrlPipe,
    PurchaseableRewardsSortPipe,
    SlugPipe,
    PossessionPipe,
    BankedRewardFilterPipe,
    CategoryFromProductPipe,
    SecretProductsPipe,
    SecretCategoriesPipe,
    GeoDistancePipe,
    ProductNotInCartPipe,
    IsSingleUsePipe,
    LocationMarkerPipe,
    IsNanPipe,
    UnreadMessagePipe,
    CheckIfUnreadNotificationPipe,
    TimeComparisonPipe,
    TimeSelectShowMoreCheckPipe,
    SelectedOptionsPipe,
    OptionGroupCompletionPipe,
    ProductCustomizationCompletionPipe,
    IsOptionValidPipe,
    MaxChoiceQuantityPipe,
    MaxGroupQuantityPipe,
    OptionGroupFullPipe,
    DedupeAppliedRewardsPipe,
    FavoriteLocationPickerPipe,
    SplashscreenBgPipe,
    GetFormControlFromParentPipe,
    StreetAddressPipe,
    DefaultCategoryImagePipe,
    RemoveCouponRewardsPipe,
    TextColorPipe,
    FeaturesFilterPipe,
    TimezoneConvertPipe,
    SavedDeliveryAddressFilterPipe,
  ],
  imports: [CommonModule],
  providers: [
    BootstrapIconPipe,
    CapitalizePipe,
    CreditCardTransformPipe,
    DaypartCategoriesPipe,
    DaypartProductsPipe,
    HexToRgbCssPipe,
    HexToRgbObjectPipe,
    NavLinkPipe,
    OptionGroupFilter,
    OptionsListPipe,
    PhonePipe,
    SearchPipe,
    SelectedOptionsPipe,
    SvgObjectFilterPipe,
    TextTruncatePipe,
    AppliedRewardsFilterPipe,
    RadialGradientPipe,
    RewardCardBackgroundPipe,
    SanitizeHtmlPipe,
    RewardsBackgroundImagePipe,
    CssUrlPipe,
    PurchaseableRewardsSortPipe,
    SlugPipe,
    PossessionPipe,
    BankedRewardFilterPipe,
    CategoryFromProductPipe,
    SecretProductsPipe,
    SecretCategoriesPipe,
    GeoDistancePipe,
    ProductNotInCartPipe,
    IsSingleUsePipe,
    LocationMarkerPipe,
    IsNanPipe,
    SelectedOptionsPipe,
    OptionGroupCompletionPipe,
    ProductCustomizationCompletionPipe,
    IsOptionValidPipe,
    MaxChoiceQuantityPipe,
    MaxGroupQuantityPipe,
    OptionGroupFullPipe,
    DedupeAppliedRewardsPipe,
    FavoriteLocationPickerPipe,
    SplashscreenBgPipe,
    GetFormControlFromParentPipe,
    StreetAddressPipe,
    DefaultCategoryImagePipe,
    RemoveCouponRewardsPipe,
  ],
  exports: [
    BootstrapIconPipe,
    CapitalizePipe,
    CreditCardTransformPipe,
    DaypartCategoriesPipe,
    DaypartProductsPipe,
    HexToRgbCssPipe,
    HexToRgbObjectPipe,
    NavLinkPipe,
    OptionGroupFilter,
    OptionsListPipe,
    PhonePipe,
    SearchPipe,
    SelectedOptionsPipe,
    SvgObjectFilterPipe,
    TextTruncatePipe,
    AppliedRewardsFilterPipe,
    RadialGradientPipe,
    RewardCardBackgroundPipe,
    SanitizeHtmlPipe,
    RewardsBackgroundImagePipe,
    CssUrlPipe,
    PurchaseableRewardsSortPipe,
    SlugPipe,
    PossessionPipe,
    BankedRewardFilterPipe,
    CategoryFromProductPipe,
    SecretProductsPipe,
    SecretCategoriesPipe,
    GeoDistancePipe,
    ProductNotInCartPipe,
    IsSingleUsePipe,
    LocationMarkerPipe,
    IsNanPipe,
    UnreadMessagePipe,
    CheckIfUnreadNotificationPipe,
    TimeComparisonPipe,
    TimeSelectShowMoreCheckPipe,
    SelectedOptionsPipe,
    OptionGroupCompletionPipe,
    ProductCustomizationCompletionPipe,
    IsOptionValidPipe,
    MaxChoiceQuantityPipe,
    MaxGroupQuantityPipe,
    OptionGroupFullPipe,
    DedupeAppliedRewardsPipe,
    FavoriteLocationPickerPipe,
    SplashscreenBgPipe,
    GetFormControlFromParentPipe,
    StreetAddressPipe,
    DefaultCategoryImagePipe,
    RemoveCouponRewardsPipe,
    TextColorPipe,
    FeaturesFilterPipe,
    TimezoneConvertPipe,
    SavedDeliveryAddressFilterPipe,
  ],
})
export class PipesModule {}
