import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  // Sets the brochure view changing breakpoint (this could be modified to have multiple e.g. match bootstrap or ionic)
  mobileBreakpoint = 991;

  // Sets a flag based on the the breakpoint above
  isMobile: boolean;
  mode: string;
  timeout;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  /**
   * Constructor for the mobile service
   */
  constructor() {
    const url = window.location.href;
    let httpParams;
    if (url.includes('?')) {
      httpParams = new HttpParams({ fromString: url.split('?')[1] });
    }
    const existingMode = sessionStorage.getItem('mode');
    if (existingMode) {
      if (httpParams && httpParams.get('mode')) {
        if (httpParams.get('mode') === existingMode) {
          // keep mode
          if (existingMode === 'tableside') {
            this.setTableSideMode(httpParams);
          } else if (existingMode === 'kiosk') {
            this.setKioskMode(httpParams);
          } else {
            this.mode = existingMode;
          }
        } else {
          // change mode
          if (httpParams.get('mode') === 'tableside' && httpParams.get('location') && httpParams.get('tablenumber')) {
            this.setTableSideMode(httpParams);
          } else if (httpParams.get('mode') === 'kiosk' && (httpParams.get('location') || httpParams.get('kiosknumber'))) {
            this.setKioskMode(httpParams);
          } else {
            this.setMobileOrDesktopMode();
          }
        }
      } else {
        // no params or previous mode
        if (existingMode === 'mobile' || existingMode === 'desktop') {
          this.setMobileOrDesktopMode();
        } else {
          // keep mode
          if (existingMode === 'tableside') {
            this.setTableSideMode(httpParams);
          } else if (existingMode === 'kiosk') {
            this.setKioskMode(httpParams);
          } else {
            this.mode = existingMode;
          }
        }
      }
    } else {
      // no existing mode
      if (httpParams && httpParams.get('mode') === 'tableside') {
        this.setTableSideMode(httpParams);
      } else if (httpParams && httpParams.get('mode') === 'kiosk') {
        this.setKioskMode(httpParams);
      } else {
        this.setMobileOrDesktopMode();
      }
    }
    if (httpParams && httpParams.get('olo-mode') === 'ios') {
      sessionStorage.setItem('olo-mode', 'ios');
    } else if (httpParams && httpParams.get('olo-mode') === 'android') {
      sessionStorage.setItem('olo-mode', 'android');
    }
  }

  setKioskMode(httpParams: HttpParams) {
    this.mode = 'kiosk';
    sessionStorage.setItem('mode', 'kiosk');
    sessionStorage.setItem('olo-mode', 'kiosk');
    if (httpParams) {
      if (httpParams.get('kiosknumber')) {
        sessionStorage.setItem('kiosknumber', httpParams.get('kiosknumber'));
      }
      if (httpParams.get('location')) {
        localStorage.setItem('kiosklocation', httpParams.get('location'));
      }
    }
  }

  setTableSideMode(httpParams: HttpParams) {
    this.mode = 'tableside';
    sessionStorage.setItem('mode', 'tableside');
    if (httpParams && httpParams.get('tablenumber')) {
      sessionStorage.setItem('tablenumber', httpParams.get('tablenumber'));
    }
    if (httpParams && httpParams.get('location')) {
      sessionStorage.setItem('tablesidelocation', httpParams.get('location'));
    }
    // clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //     this.setMobileOrDesktopMode();
    //     window.history.pushState({}, document.title, '/');
    //     window.location.reload();
    // }, 3600000);
  }

  createTimeout() {
    this.setMobileOrDesktopMode();
  }

  setMobileOrDesktopMode() {
    // Sets the mobile breakpoint as a CSS property variable
    document.documentElement.style.setProperty('--mobile-breakpoint', this.mobileBreakpoint.toString(10) + 'px');
    // Set breakpoint on initialization
    this.mobileCheck();
    // Adds an event listener to the resize window event to help trigger value changes
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.mobileCheck();
    });
  }

  /**
   * Checks the window width against the break point to determine which views to use (mobile or desktop)
   */
  mobileCheck() {
    // Grab the inner width of the window
    const width = window.innerWidth;

    // Sets the flag based on the breakpoint set above
    this.isMobile = width <= this.mobileBreakpoint;
    this.mode = width <= this.mobileBreakpoint ? 'mobile' : 'desktop';
    sessionStorage.setItem('mode', this.mobileBreakpoint ? 'mobile' : 'desktop');
  }

  isWeb() {
    return this.mode !== 'tableside' && this.mode !== 'kiosk' && Capacitor.getPlatform() === 'web';
  }
}
