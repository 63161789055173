import { Injectable } from '@angular/core';
import { Order } from 'src/interfaces/order.interface';
import { Observable, throwError } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { CreateNewOrder, SetOrCreateOrder, StartOrderForDelivery, UpdateDelivery } from 'src/store/actions/order.actions';
import { SetDeliveryLocations } from 'src/store/actions/location.actions';
import { SetMenu } from 'src/store/actions/menu.actions';
import { ICoordinates } from '../../locations/models/coordinates.interface';
import { Address } from 'src/interfaces/address.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { HandoffTypeService } from '@modules/cart/services/handoff-type.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferOrderModalComponent } from 'src/modules/cart/components/transfer-order-modal/transfer-order-modal.component';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { VendorSetup } from '../../../interfaces/vendor.interface';
import { AddressEmit, OrderTypeModalComponent } from '@modules/cart/components/order-type-modal/order-type-modal.component';
import { NavigationService } from '@modules/navigation/services';
import { filter, take } from 'rxjs/operators';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { GlobalStateModel } from '../../../store/state.model';
import { HttpErrorResponse } from '@angular/common/http';
import { produce } from 'immer';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeService {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;

  displayOrder: Order;
  deliveryAddress: Address;
  noMenuRoute = ['upsell', 'cart', 'checkout'];
  locationID: string;

  constructor(
    private store: Store,
    private handoffTypeService: HandoffTypeService,
    private router: Router,
    private navigation: NavigationService,
    private modalService: NgbModal,
    private directus: DirectusService
  ) {
    this.order$.subscribe(order => {
      this.displayOrder = order ? order : null;
    });
  }

  startOrderFromSaved(savedLoc: ISavedAddress, isInit: boolean) {
    if (savedLoc.orderType === 'pickup') {
      return this.startOrder(savedLoc.location, true, HandoffType.pickup, isInit);
    } else {
      this.deliveryAddress = savedLoc.address;
      return this.startOrder(savedLoc.location, false, HandoffType.delivery, isInit);
    }
  }

  startOrder(loc: DineEngineLocation, isPickup = false, selectedHandoffType: HandoffType, isInit: boolean): Promise<any> {
    if (selectedHandoffType === HandoffType.delivery || selectedHandoffType === HandoffType.dispatch || !isPickup) {
      if (loc.supportsDelivery) {
        selectedHandoffType = HandoffType.delivery;
      } else if (loc.supportsDispatch) {
        selectedHandoffType = HandoffType.dispatch;
      }
    }
    if (isPickup && !loc.supportsPickup && selectedHandoffType === HandoffType.pickup) {
      selectedHandoffType = HandoffType.curbside;
    }
    // tslint:disable-next-line: max-line-length
    if (
      this.displayOrder &&
      selectedHandoffType === this.displayOrder.handoffType &&
      loc.locationID === this.displayOrder.location.locationID
    ) {
      // No transfer needed, it's all the same.
      // tslint:disable-next-line:max-line-length
      if (
        (selectedHandoffType === HandoffType.delivery || selectedHandoffType === HandoffType.dispatch) &&
        this.deliveryAddress !== this.displayOrder.deliveryAddress
      ) {
        return this.store
          .dispatch(new SetMenu(loc.locationID, selectedHandoffType))
          .toPromise()
          .then(() => {
            return this.store
              .dispatch(new UpdateDelivery(this.deliveryAddress))
              .toPromise()
              .then(() => {
                return this.routeToLink(loc, selectedHandoffType, isInit);
              })
              .catch((error: Error) => {
                throw new Error(error.message);
              });
          })
          .catch((error: Error) => {
            throw new Error(error.message);
          });
      } else {
        return this.routeToLink(loc, selectedHandoffType, isInit);
      }
    } else if (!this.displayOrder) {
      // Also no transfer needed, start a new basket.
      return this.store
        .dispatch(new SetMenu(loc.locationID, selectedHandoffType))
        .toPromise()
        .catch((error: HttpErrorResponse) => {
          console.log('error 8');
          throw new Error(error.error.message ?? error.message);
        })
        .then(() => {
          return this.store
            .dispatch(new CreateNewOrder(loc.locationID, selectedHandoffType, this.deliveryAddress))
            .toPromise()
            .catch((error: HttpErrorResponse) => {
              console.log('error 7');
              throw new Error(error.error.message ?? error.message);
            })
            .then(() => {
              return this.routeToLink(loc, selectedHandoffType, isInit);
            });
        });
    } else {
      // Attempt to transfer the basket. If unsuccessful, show popup
      if (selectedHandoffType === HandoffType.delivery || selectedHandoffType === HandoffType.dispatch) {
        return this.store
          .dispatch(new SetMenu(loc.locationID, selectedHandoffType))
          .toPromise()
          .catch(error => {
            console.log('error 2');
            console.error(error);
            return this.showTransferModal(loc, selectedHandoffType);
          })
          .then(() => {
            // tslint:disable-next-line:max-line-length
            return this.store
              .dispatch(new StartOrderForDelivery(loc.locationID, selectedHandoffType, this.deliveryAddress))
              .toPromise()
              .catch(error => {
                console.log('error 1');
                if ((error.error?.message as string)?.toLowerCase().includes('no delivery services')) {
                  throw new Error('Delivery drivers are not available at this time. Please select a different location.');
                } else if ((error.error?.message as string)?.toLowerCase().includes('not available')) {
                  throw new Error(error.error.message);
                } else {
                  return this.showTransferModal(loc, selectedHandoffType);
                }
              })
              .then(() => {
                return this.routeToLink(loc, selectedHandoffType, isInit).catch(() => this.showTransferModal(loc, selectedHandoffType));
              });
          });
      } else {
        return this.store
          .dispatch(new SetMenu(loc.locationID, selectedHandoffType))
          .toPromise()
          .catch(error => {
            console.log('error 4');
            console.error(error);
            return this.showTransferModal(loc, selectedHandoffType);
          })
          .then(() => {
            return this.store
              .dispatch(new SetOrCreateOrder(loc.locationID, selectedHandoffType))
              .toPromise()
              .catch(error => {
                console.log('error 3');
                console.error(error);
                return this.showTransferModal(loc, selectedHandoffType);
              })
              .then(() => {
                return this.routeToLink(loc, selectedHandoffType, isInit).catch(() => this.showTransferModal(loc, selectedHandoffType));
              });
          });
      }
    }
  }

  showTransferModal(transferLoc: DineEngineLocation, selectedHandoffType: HandoffType) {
    this.modalService.dismissAll();
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(TransferOrderModalComponent, { centered: true, animation: true });
      modalRef.componentInstance.confirmChangeEmit.subscribe(() => {
        modalRef.componentInstance.isLoading = true;
        this.store
          .dispatch(new SetMenu(transferLoc.locationID, selectedHandoffType))
          .toPromise()
          .then(() => {
            this.store
              .dispatch(new CreateNewOrder(transferLoc.locationID, selectedHandoffType, this.deliveryAddress))
              .toPromise()
              .then(() => {
                modalRef.componentInstance.isLoading = false;
                return this.routeToLink(transferLoc, selectedHandoffType, false).then(resolve);
              })
              .catch(err => {
                modalRef.componentInstance.isLoading = false;
                if (typeof err === 'string') {
                  modalRef.componentInstance.errorMessage = err;
                } else if (typeof err === 'object') {
                  modalRef.componentInstance.errorMessage = err.error.message;
                }
                reject(err);
              });
          });
      });
      modalRef.componentInstance.keepOldLocationEmit.subscribe(() => {
        // tslint:disable-next-line:max-line-length
        this.store
          .dispatch(new SetOrCreateOrder(this.displayOrder.location.locationID, this.displayOrder.handoffType))
          .toPromise()
          .then(() => {
            return this.routeToLink(this.displayOrder.location, this.displayOrder.handoffType, false).then(resolve).catch(console.log);
          });
      });
    });
  }

  routeToLink(loc: DineEngineLocation, selectedHandoffType: HandoffType, isInit: boolean): Promise<boolean> {
    this.handoffTypeService.saveHandoffToLocalStorage(selectedHandoffType.toString());
    const url = window.location.href;
    if (!this.noMenuRoute.some(sub => url.includes(sub)) && !isInit) {
      if (url.includes('switchTo') && !url.includes('#menu')) {
        return this.navigation.navigateToCheckoutPage().then(value => {
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 0);
          return value;
        });
      } else {
        const restaurantID = loc.slugURL ? loc.slugURL : loc.locationID;
        return this.navigation.navigateToMenuPage(restaurantID).then(value => {
          setTimeout(() => {
            this.modalService.dismissAll();
          }, 0);
          return value;
        });
      }
    } else {
      setTimeout(() => {
        this.modalService.dismissAll();
      }, 0);
    }
  }

  checkDeliveryAddress(deliveryInfo: AddressEmit): Promise<any> {
    this.deliveryAddress = deliveryInfo.address.addressComponents;
    this.deliveryAddress = produce(this.deliveryAddress, draft => {
      draft.address2 = deliveryInfo.secondAddress;
    });
    this.deliveryAddress = produce(this.deliveryAddress, draft => {
      draft.specialInstructions = deliveryInfo.instructions;
    });
    const deliveryCoords: ICoordinates = {
      latitude: this.deliveryAddress.latitude,
      longitude: this.deliveryAddress.longitude,
    };
    return this.store
      .dispatch(new SetDeliveryLocations(deliveryCoords, 20, this.deliveryAddress))
      .toPromise()
      .then((res: any) => {
        if (res && res.location && res.location.deliveryLocations.length) {
          // start order with first canDeliver location
          let canDeliverLoc = null;
          res.location.deliveryLocations.forEach((loc: DineEngineLocation) => {
            if (loc.canDeliver && !canDeliverLoc) {
              canDeliverLoc = loc;
            }
          });
          if (canDeliverLoc) {
            return this.startOrder(canDeliverLoc, false, HandoffType.delivery, false).catch(() => {
              throw throwError('No Location Available');
            });
          } else {
            throw throwError('No location Available');
          }
        } else {
          throw throwError('No location Available');
        }
      });
  }

  startPickupOrder(locationID?: string): Observable<any> {
    return this.store.dispatch(new SetOrCreateOrder(locationID ? locationID : this.locationID, HandoffType.pickup));
  }

  startCurbsideOrder(locationID?: string): Observable<any> {
    return this.store.dispatch(new SetOrCreateOrder(locationID ? locationID : this.locationID, HandoffType.curbside));
  }

  startDriveThruOrder(locationID?: string): Observable<any> {
    return this.store.dispatch(new SetOrCreateOrder(locationID ? locationID : this.locationID, HandoffType.driveThru));
  }

  startDineInOrder(locationID?: string): Observable<any> {
    return this.store.dispatch(new SetOrCreateOrder(locationID ? locationID : this.locationID, HandoffType.dineIn));
  }

  startDeliveryOrder() {
    const modalRef = this.modalService.open(OrderTypeModalComponent, {
      centered: true,
      fullscreen: true,
      animation: false,
      modalDialogClass: 'bg-light',
    });
    modalRef.componentInstance.orderType = 'delivery';
    modalRef.componentInstance.startDeliveryOrderEmit.subscribe((deliveryInfo: AddressEmit) => {
      this.checkDeliveryAddress(deliveryInfo)
        .then(
          () => {
            modalRef.componentInstance.isLoading = false;
          },
          error => {
            let errorMessage = 'We ran into an issue checking your address. Please try again later.';
            if (error.error?.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            }
            modalRef.componentInstance.displayError = errorMessage;
            modalRef.componentInstance.isLoading = false;
          }
        )
        .catch(error => console.log('checkout error', error));
    });
    modalRef.componentInstance.startDeliveryWithLocation.subscribe((res: AddressEmit) => {
      this.deliveryAddress = res.address.addressComponents;
      this.deliveryAddress = produce(this.deliveryAddress, draft => {
        draft.address2 = res.secondAddress;
      });
      this.deliveryAddress = produce(this.deliveryAddress, draft => {
        draft.specialInstructions = res.instructions;
      });
      modalRef.componentInstance.isLoading = true;
      modalRef.componentInstance.selectedLocation = res.location;
      this.startOrder(res.location, false, HandoffType.delivery, false)
        .then(() => {
          modalRef.componentInstance.isLoading = false;
          modalRef.componentInstance.selectedLocation = null;
        })
        .catch(error => {
          modalRef.componentInstance.isLoading = false;
          modalRef.componentInstance.selectedLocation = null;
          modalRef.componentInstance.chooseDelivery = false;
          let errorMessage = 'We ran into an issue starting your order. Please try again later.';
          if (error.error?.message) {
            errorMessage = error.error.message;
          } else if (error.message) {
            errorMessage = error.message;
          }
          modalRef.componentInstance.displayError = errorMessage;
        });
    });
    modalRef.componentInstance.startPickupOrderEmit.subscribe(_ => {
      const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
      if (order && (order.handoffType === HandoffType.dispatch || order.handoffType === HandoffType.delivery)) {
        if (this.router.url.startsWith('/locations') && !this.router.url.startsWith('/locations/')) {
          this.modalService.dismissAll();
        } else {
          this.navigation.navigateToPickupLocationsPage(true).then(() => {
            setTimeout(() => this.modalService.dismissAll(), 300);
          });
        }
      } else {
        modalRef.close();
      }
    });
  }

  changeDeliveryAddress(location: DineEngineLocation, handoffType: HandoffType, isInit: boolean): Promise<any> {
    return this.store
      .dispatch(new SetMenu(location.locationID, handoffType))
      .toPromise()
      .then(() => {
        return this.store
          .dispatch(new UpdateDelivery(this.deliveryAddress))
          .toPromise()
          .then(() => {
            return this.routeToLink(location, handoffType, isInit);
          })
          .catch(() => throwError('Error'));
      })
      .catch(() => throwError('Error'));
  }

  startNewBasket(location: DineEngineLocation, handoffType: HandoffType, isInit: boolean): Promise<any> {
    return this.store
      .dispatch(new SetMenu(location.locationID, handoffType))
      .toPromise()
      .then(() => {
        return this.store
          .dispatch(new CreateNewOrder(location.locationID, handoffType, this.deliveryAddress))
          .toPromise()
          .then(() => {
            return this.routeToLink(location, handoffType, isInit);
          })
          .catch(() => throwError('Error'));
      })
      .catch(() => throwError('Error'));
  }

  transferBasket(location: DineEngineLocation, handoffType: HandoffType, isInit: boolean): Promise<any> {
    // Attempt to transfer the basket. If unsuccessful, show popup
    if (handoffType === 2 || handoffType === 3) {
      return this.store
        .dispatch(new SetMenu(location.locationID, handoffType))
        .toPromise()
        .then(() => {
          // tslint:disable-next-line:max-line-length
          return this.store
            .dispatch(new StartOrderForDelivery(location.locationID, handoffType, this.deliveryAddress))
            .toPromise()
            .then(
              () => {
                return this.routeToLink(location, handoffType, isInit);
              },
              () => {
                return this.showTransferModal(location, handoffType);
              }
            )
            .catch(error => {
              console.log('error 1');
              console.error(error);
              return this.showTransferModal(location, handoffType);
            });
        })
        .catch(error => {
          console.log('error 2');
          console.error(error);
          return this.showTransferModal(location, handoffType);
        });
    } else {
      return this.store
        .dispatch(new SetMenu(location.locationID, handoffType))
        .toPromise()
        .then(() => {
          return this.store
            .dispatch(new SetOrCreateOrder(location.locationID, handoffType))
            .toPromise()
            .then(
              () => {
                return this.routeToLink(location, handoffType, isInit);
              },
              () => {
                return this.showTransferModal(location, handoffType);
              }
            )
            .catch(error => {
              console.log('error 3');
              console.error(error);
              return this.showTransferModal(location, handoffType);
            });
        })
        .catch(error => {
          console.log('error 4');
          console.error(error);
          return this.showTransferModal(location, handoffType);
        });
    }
  }

  setNewOrderType(fromMenu: boolean, locationID?: string) {
    this.store
      .select((state: GlobalStateModel) => state.app.oloConfig)
      .pipe(
        filter(oc => !!oc),
        take(1)
      )
      .subscribe(oloConfig => {
        if (oloConfig && oloConfig.wrap_olo_serve && Capacitor.getPlatform() !== 'web') {
          this.navigation.navigateToLocationsPage();
        } else {
          this.vendorSetup$.pipe(filter(vs => !!vs)).subscribe((res: VendorSetup) => {
            if (res.pickup_provider === 'none') {
              this.openOrderTypeModal(1, fromMenu);
            } else if (res.delivery_provider === 'none') {
              this.navigation.navigateToLocationsPage();
            } else {
              this.openOrderTypeModal(null, fromMenu, locationID);
            }
          });
        }
      });
  }

  setNewOrderTypeWithLocation(fromMenu: boolean, id: string) {
    this.locationID = id;
    this.vendorSetup$.pipe(filter(vs => !!vs)).subscribe((res: VendorSetup) => {
      if (res.pickup_provider === 'none') {
        this.openOrderTypeModal(1, fromMenu);
      } else if (res.delivery_provider === 'none') {
        this.navigation.navigateToLocationsPage();
      } else {
        this.openOrderTypeModal(null, fromMenu);
      }
    });
  }

  private openOrderTypeModal(orderType: number | null, fromMenu?: boolean, locationID?: string) {
    const modalRef = this.modalService.open(OrderTypeModalComponent, {
      centered: true,
      backdrop: fromMenu ? 'static' : true,
      keyboard: !fromMenu,
      fullscreen: true,
      animation: false,
      modalDialogClass: 'bg-light',
    });
    modalRef.componentInstance.orderType = orderType;
    modalRef.componentInstance.startPickupOrderEmit.subscribe(() => {
      if (fromMenu === true) {
        this.store.dispatch(new SetOrCreateOrder(locationID ?? this.locationID, HandoffType.pickup)).subscribe(() => {
          setTimeout(() => this.modalService.dismissAll(), 300);
        });
      } else {
        if (locationID) {
          modalRef.componentInstance.pickupLoading = true;
          this.store.dispatch(new SetOrCreateOrder(locationID, HandoffType.pickup)).subscribe(
            async () => {
              await this.navigation.navigateToMenuPage(locationID);
              this.modalService.dismissAll();
              modalRef.componentInstance.pickupLoading = false;
            },
            () => (modalRef.componentInstance.pickupLoading = false)
          );
        } else if (this.router.url.startsWith('/locations') && !this.router.url.startsWith('/locations/')) {
          this.modalService.dismissAll();
        } else {
          this.navigation.navigateToPickupLocationsPage(true).then(() => {
            setTimeout(() => this.modalService.dismissAll(), 300);
          });
        }
      }
    });
    modalRef.componentInstance.closeModalClicked.subscribe(() => {
      if (fromMenu === true) {
        this.navigation.navigateToLocationsPage();
      }
    });
    modalRef.componentInstance.startDeliveryOrderEmit.subscribe((deliveryInfo: AddressEmit) => {
      if (typeof deliveryInfo.address === 'undefined') {
        modalRef.componentInstance.displayError = 'Please select an address from the dropdown.';
        modalRef.componentInstance.isLoading = false;
      } else {
        this.checkDeliveryAddress(deliveryInfo).then(
          () => {
            modalRef.componentInstance.isLoading = false;
          },
          error => {
            let errorMessage = 'We ran into an issue starting your order. Please try again later.';
            if (error.error?.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            }
            // tslint:disable-next-line:max-line-length
            modalRef.componentInstance.displayError = errorMessage;
            modalRef.componentInstance.isLoading = false;
          }
        );
      }
    });
    modalRef.componentInstance.startDeliveryWithLocation.subscribe((res: AddressEmit) => {
      this.deliveryAddress = res.address.addressComponents;
      this.deliveryAddress = produce(this.deliveryAddress, draft => {
        draft.address2 = res.secondAddress;
      });
      this.deliveryAddress = produce(this.deliveryAddress, draft => {
        draft.specialInstructions = res.instructions;
      });
      modalRef.componentInstance.isLoading = true;
      modalRef.componentInstance.selectedLocation = res.location;
      this.startOrder(res.location, false, HandoffType.delivery, false)
        .then(() => {
          modalRef.componentInstance.isLoading = false;
          modalRef.componentInstance.selectedLocation = null;
        })
        .catch(error => {
          modalRef.componentInstance.isLoading = false;
          modalRef.componentInstance.selectedLocation = null;
          modalRef.componentInstance.chooseDelivery = false;
          let errorMessage = 'We ran into an issue starting your order. Please try again later.';
          if (error.error?.message) {
            errorMessage = error.error.message;
          } else if (error.message) {
            errorMessage = error.message;
          }
          modalRef.componentInstance.displayError = errorMessage;
        });
    });
    // modalRef.closed.subscribe(() => this.orderNowLoading = false);
    // modalRef.dismissed.subscribe(() => this.orderNowLoading = false);
  }
}
