import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { GeolocationService } from '@modules/locations/services/geolocation.service';
import { Geolocation, Position } from '@capacitor/geolocation';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { Address } from '../../../interfaces/address.interface';
import { from } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { SetPickupLocations } from '../../../store/actions/location.actions';
import { Select, Store } from '@ngxs/store';
import { Location as DineEngineLocation } from '../../../interfaces/location.interface';
import { HandoffType } from '../../../interfaces/handoff-type.enum';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { AddressSearch } from '@modules/locations/models/locations.address-search';
import { VendorSetup } from '../../../interfaces/vendor.interface';
import { ToastService } from '../../../services/toast.service';
import { GlobalStateModel } from '../../../store/state.model';

// Move this into a model file
interface GeolocationPositionError {
  code: number;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.app.googleMapsLoaded) googleMapsLoaded$: Observable<boolean>;

  locationProvider;

  private geolocationAllowedSubject = new BehaviorSubject<boolean>(this.checkAuthorizedGeoLocation());
  geolocationAllowed$ = this.geolocationAllowedSubject.asObservable();

  // absolute fallback is (0.0, 0.0), this could be set to something more sensible
  // like the center of the US or like the average middle of the pins available
  fallbackCoordinates: ICoordinates = {
    latitude: 0.0,
    longitude: 0.0,
  };
  userCoordinates: ICoordinates;
  userCoords: ICoordinates = null;
  lat: number;
  lng: number;

  geolocationAllowed = true;
  gettingGeolocation = false;

  // Finding Locations
  isLoading = false;
  findingLocations = false;
  infoMessage = 'Please search manually above by City & State or Zip Code.';

  // Select Location
  locationLoading;

  // Search Locations
  searchRan = false;
  addressSearch: AddressSearch;
  errorMessage = '';

  private gettingGeolocationSubject = new BehaviorSubject<boolean>(false);
  gettingGeolocation$ = this.gettingGeolocationSubject.asObservable();

  private userCoordinatesSubject = new BehaviorSubject<ICoordinates>(null);
  private userAddressSubject = new BehaviorSubject<Address>(null);
  userCoordinates$ = this.userCoordinatesSubject.asObservable();
  userAddress$ = this.userAddressSubject.asObservable();

  constructor(
    private geoLocation: GeolocationService,
    private userLocation: UserLocationService,
    private geo: GeocodingService,
    private store: Store,
    private orderType: OrderTypeService,
    private toast: ToastService
  ) {
    this.geo.geocodeByIp().subscribe(
      location => {
        this.store.dispatch(new SetPickupLocations({ latitude: location.lat, longitude: location.lon })).subscribe(
          () => {
            this.getAddress();
          },
          error => {
            this.getAddress();
          }
        );
      },
      error => {
        this.getAddress();
      }
    );
    combineLatest([this.googleMapsLoaded$, this.userCoordinates$]).subscribe(([google, coordinates]) => {
      if (google && coordinates) {
        const address: Address = this.userLocation.getUserLocation();
        if (address) {
          this.userAddressSubject.next(address);
        } else {
          const userCoordinates = {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          };
          this.reverseGeocodeAndStore(userCoordinates).then((add: Address) => {
            this.userAddressSubject.next(add);
          });
        }
      }
    });
    this.vendorSetup$.subscribe(setup => {
      if (setup) {
        this.locationProvider = setup.location_search_provider;
      }
    });
  }

  getLocations$(): Observable<{}> {
    return of({});
  }

  // User geocoding, used primarily with the location pin

  getUserGeocode() {
    this.geoLocation.getClientLocation(this.clientLocationSuccess.bind(this), this.clientLocationError.bind(this));
  }

  locationSelected(loc: DineEngineLocation) {
    if (loc.isLive) {
      if (!loc.supportsPickup && !loc.supportsCurbside && !loc.supportsDriveThru) {
        this.orderType.startDeliveryOrder();
      } else {
        this.locationLoading = loc.locationID;
        this.orderType.startOrder(loc, true, HandoffType.pickup, false).then(
          () => {
            this.locationLoading = '';
          },
          (error: Error) => {
            this.toast.danger(error.message);
            this.locationLoading = '';
          }
        );
      }
    } else {
      // Set error to say the location is not live
    }
  }

  searchTextChanged(addressSearch: AddressSearch) {
    this.searchRan = true;
    // this.infoMessage = 'We were unable to find any locations near you.';
    this.addressSearch = addressSearch;
    if (addressSearch.addressComponents && addressSearch.addressComponents.latitude && addressSearch.addressComponents.longitude) {
      this.isLoading = true;
      const locationCoords: ICoordinates = {
        latitude: addressSearch.addressComponents.latitude,
        longitude: addressSearch.addressComponents.longitude,
      };
      localStorage.setItem(
        'lsl',
        JSON.stringify({
          latitude: locationCoords.latitude,
          longitude: locationCoords.longitude,
        })
      );
      this.store
        .dispatch(new SetPickupLocations(locationCoords))
        .toPromise()
        .then(() => {
          this.userCoords = { latitude: locationCoords.latitude, longitude: locationCoords.longitude };
        })
        .catch(error => {
          this.isLoading = false;
          this.errorMessage = error.error.message;
        });
    } else {
      this.userCoords.latitude = this.userCoords.longitude = 0;
      this.geo.geocode(addressSearch.formattedAddress).then((results: google.maps.LatLngLiteral) => {
        localStorage.setItem('lsl', JSON.stringify({ latitude: results.lat, longitude: results.lng }));
        this.store.dispatch(new SetPickupLocations({ latitude: results.lat, longitude: results.lng }));
      });
    }
  }

  getUserLocation() {
    this.addressSearch = null;
    // this.userCoords = null;
    // this.findingLocations = true;
    this.setGettingGeolocation(true);
    from(
      Geolocation.getCurrentPosition({
        enableHighAccuracy: Capacitor.getPlatform() !== 'ios',
        timeout: 10000,
      })
    ).subscribe({
      next: pos => {
        // this.infoMessage = 'We were unable to find any locations near you. Please search manually above by City & State or Zip Code.';
        this.store
          .dispatch(new SetPickupLocations({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }))
          .subscribe(() => this.setGettingGeolocation(false));
        this.userCoords = { latitude: pos.coords.latitude, longitude: pos.coords.longitude };
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        localStorage.setItem('authorizedGeoLocation', String(1));
        this.geolocationAllowedSubject.next(this.checkAuthorizedGeoLocation());
      },
      error: error => {
        if (Capacitor.getPlatform() === 'web') {
          navigator.geolocation.getCurrentPosition(
            pos => {
              localStorage.setItem('authorizedGeoLocation', String(1));
              this.geolocationAllowedSubject.next(this.checkAuthorizedGeoLocation());
              // this.infoMessage = 'We were unable to find any locations near you. Please search manually above by City & State or Zip Code.';
              this.store
                .dispatch(new SetPickupLocations({ latitude: pos.coords.latitude, longitude: pos.coords.longitude }))
                .subscribe(() => this.setGettingGeolocation(false));
              this.userCoords = { latitude: pos.coords.latitude, longitude: pos.coords.longitude };
              this.lat = pos.coords.latitude;
              this.lng = pos.coords.longitude;
            },
            err => {
              localStorage.setItem('authorizedGeoLocation', String(0));
              this.geolocationAllowedSubject.next(this.checkAuthorizedGeoLocation());
              this.findingLocations = false;
              this.infoMessage = `Your ${
                Capacitor.getPlatform() === 'web' ? "browser's" : "app's"
              } geolocation has not been enabled. Please search manually above by City & State or Zip Code.`;
              // this.store.dispatch(new SetPickupLocations(this.fallbackCoordinates)).subscribe(() => this.setGettingGeolocation(false));
              this.setGettingGeolocation(false);
            }
          );
        } else {
          this.findingLocations = false;
          localStorage.setItem('authorizedGeoLocation', String(0));
          this.geolocationAllowedSubject.next(this.checkAuthorizedGeoLocation());
          this.infoMessage = `Your ${
            Capacitor.getPlatform() === 'web' ? "browser's" : "app's"
          } geolocation has not been enabled. Please search manually above by City & State or Zip Code.`;
          // this.store.dispatch(new SetPickupLocations(this.fallbackCoordinates)).subscribe(() => this.setGettingGeolocation(false));
          this.setGettingGeolocation(false);
        }
      },
    });
  }

  checkAuthorizedGeoLocation() {
    // you can use this function to know if geoLocation was previously allowed
    return localStorage.getItem('authorizedGeoLocation') === '1' || localStorage.getItem('authorizedGeoLocation') === null;
  }

  private clientLocationSuccess(position: Position) {
    const geolocationCoordinates = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    if (geolocationCoordinates) {
      this.userCoordinatesSubject.next(geolocationCoordinates);
    } else {
      this.geo.geocodeByIp().subscribe(location => {
        const ipCoordinates: ICoordinates = {
          latitude: location.lat,
          longitude: location.lon,
          isGeoIP: false,
        };
        this.userCoordinatesSubject.next(ipCoordinates);
      });
    }
  }

  updateGeolocationAllowed(allowed: boolean) {
    this.geolocationAllowed = allowed;
    this.geolocationAllowedSubject.next(allowed);
  }

  private clientLocationError(error: GeolocationPositionError) {
    console.warn(`ERROR(${error.code}): ${error.message}`);
    this.geo.geocodeByIp().subscribe(location => {
      const ipCoordinates: ICoordinates = {
        latitude: location.lat,
        longitude: location.lon,
        isGeoIP: true,
      };
      this.userCoordinatesSubject.next(ipCoordinates);
    });
  }

  private reverseGeocodeAndStore(coordinates: ICoordinates) {
    return this.geo.reverseGeocode(coordinates.latitude, coordinates.longitude).then((results: any) => {
      const userAddress: Address = this.geo.googlePlaceToAddress(results);
      userAddress.latitude = coordinates.latitude;
      userAddress.longitude = coordinates.longitude;
      this.userLocation.storeUserLocation(userAddress);
      return userAddress;
    });
  }

  private getAddress() {
    const savedAddress = this.userLocation.getMostRecentLocation(); // eventually just get most recent
    if (savedAddress) {
      const savedCoordinates: ICoordinates = {
        latitude: savedAddress.address.latitude,
        longitude: savedAddress.address.longitude,
      };
      this.userCoordinatesSubject.next(savedCoordinates);
    } else {
      this.geoLocation.getClientLocation(this.clientLocationSuccess.bind(this), this.clientLocationError.bind(this));
    }
  }

  private setGettingGeolocation(next: boolean) {
    this.gettingGeolocation = next;
    this.gettingGeolocationSubject.next(next);
  }
}
