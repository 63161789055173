export const environment = {
  debug: true,
  adminUrl: 'https://{project}.stage.dineengine.io',
  production: true,
  allowConsoleErrors: true,

  domainAPI: 'https://api.stage.dineengine.io',
  domainLoyalty: 'https://mybistromobile.fishbowlcloud.com/clpapi',

  API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyAXB_lR9mmazH6ePfbDBDzRGdHjBPaDa1A', // https://github.com/mapsplugin/cordova-plugin-googlemaps#why-two-api-keys
  API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyAXB_lR9mmazH6ePfbDBDzRGdHjBPaDa1A',

  punchClientId: '753d870975b915b4f1d69ed2ba7f1f1998db0faac810b6b5e0b3e8dad840c9d1',
  punchSecret: '609390b274076fb173a427cff7efd0dc5b3ad5586ca50b0916deae2780102e28',
  punchBaseUrl: 'https://punchh.com',
  PunchUrl: 'https://api.punchh.com',
  punchhOauthPage: 'https://sandbox.punchh.com/oauth/authorize?',
  punchhOauthPageProd: 'https://api.punchh.com/oauth/authorize?',

  hotjar: '1796213',
  sentryDsn: 'https://a301db28aca3449098680468035de05a@o553654.ingest.sentry.io/5681258',
}; // environments express.js will handle this
