import { Injectable } from '@angular/core';
import { User } from 'src/interfaces/user.interface';
import { Order } from 'src/interfaces/order.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Location } from 'src/interfaces/location.interface';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { Option, OrderItemModifier } from 'src/interfaces/option.interface';
import { Category } from 'src/interfaces/category.interface';
import { TimeFrame } from 'src/interfaces/time-frame.interface';
import { Address } from '../../interfaces/address.interface';
import { Site as ItwercsSite } from './interfaces/site.interface';
import { ScreenPage, ScreenPage as ItwercsScreenPage } from './interfaces/screen-page.interface';
import { ScreenButton as ItwercsScreenButton } from './interfaces/screen-button.interface';
import { LeadThrough as ItwercsLeadThrough } from './interfaces/lead-through.interface';
import { LeadThroughItem as ItwercsLeadThroughItem } from './interfaces/lead-through-item.interface';
import { Order as ItwercsOrder } from './interfaces/order.interface';
import { OrderItem as ItwercsOrderItem } from './interfaces/order-item.interface';
import moment from 'moment-timezone';
import { Guest, GuestDetail } from './interfaces/guest.interface';
import { CreateGuestRequestGuest } from './interfaces/create-guest-request.interface';
import {
  OrderSubmitCalculateGuest,
  OrderSubmitCalculateRequest,
  OrderSubmitCalculateRequestOrderItem,
} from './interfaces/order-submit-calculate-request.interface';
import { Menu } from '../../interfaces/menu.interface';
import { MenuItem } from './interfaces/menu-item.interface';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import { PaymentResponse } from '../../interfaces/payment-response.interface';
import { OrderPayment } from './interfaces/order-payment.interface';
import { CardDetails } from '../../interfaces/card-details.interface';
import { CreditCard } from '../../interfaces/creditcard.interface';
import { OrderInterface } from './interfaces/order-interface.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import { map } from 'rxjs/operators';
import { DirectusService } from '../directus/directus.service';

@Injectable({
  providedIn: 'root',
})
export class ItwercsMappingService {
  prod = false;

  constructor(private directus: DirectusService) {
    this.directus.getItwercsSettings().pipe(
      map(config => {
        this.prod = !config.sandbox_mode;
      })
    );
  }

  siteToLocation(site: ItwercsSite): Location {
    // TODO: This still needs some work
    return {
      locationID: site.Id.toString(),
      name: site.Name,
      slugURL: null,
      address: {
        address1: site.Address1,
        address2: site.Address2,
        city: site.City,
        state: site.State,
        zipCode: site.Zip,
        latitude: Number(site.Latitude),
        longitude: Number(site.Longitude),
        country: 'US',
      } as Address,
      phoneNumber: site.Phone,
      emailAddress: site.Email,

      mapIconURL: null,

      supportsDelivery: false,
      supportsPickup: true,
      supportsCurbside: false,
      supportsDispatch: false,
      supportsDriveThru: false,
      supportsTableside: true,

      orderLeadTime: site.LeadTime,

      specialInstructionsMaxLength: 128,
      supportsSpecialInstructions: true,
      supportsRecipientName: true,
      supportsTip: true,
      supportsGuestOrdering: true,
      requiresPhoneNumber: true,
      supportsOnlineOrdering: site.IsIWOO,
      supportsSplitPayments: false,
      supportsGroupOrders: true,
      supportsBasketTransfer: false,
      customFields: null,

      deliveryHours: null,
      pickupHours: this.generateCalendars(site),
      curbsideHours: null,
      dispatchHours: null,
      driveThruHours: null,
      tablesideHours: this.generateCalendars(site),

      externalLink: site.ExternalURL,
      cateringLink: null,

      externalRef: null,

      isLive: site.IsIWOO,
      isOpen: this.determineLocationOpen(site),
      isPrivate: this.prod ? site.IsTestSite : false, // TODO: Replace with site.IsTestSite
      seoDescription: null,
      pickupInstructions: null,
      curbsideInstructions: null,
      deliveryInstructions: null,
      dispatchInstructions: null,
      tablesideInstructions: null,
      driveThruInstructions: null,

      supportsAdvancedOrders: true,
      supportsAsapOrders: true,

      orderAheadDays: 7,
    } as Location;
  }

  screenPageToCategory(screenPage: ItwercsScreenPage): Category {
    return {
      categoryID: screenPage.Id.toString(),
      name: screenPage.Name,
      nameSlug: null,
      description: null,
      products: screenPage.Buttons.map(button => this.screenButtonToProduct(button)),
      standardImageURL: null,
      thumbnailImageURL: null,
      isHidden: false,
      seoDescription: null,
    } as Category;
  }

  screenButtonToProduct(screenButton: ItwercsScreenButton): Product {
    return {
      allergenInfo: null,
      canModify: null,
      categoryID: screenButton.PageId.toString(),
      isAvailable: true,
      longDesc: null,
      menuID: null,
      name: screenButton.MenuItem.Name,
      nameSlug: null,
      nutritionInfo: null,
      optionGroups: null,
      priceCents: screenButton.Price * 100,
      productID: screenButton.MenuItemId.toString(),
      requiresModification: null,
      shortDesc: screenButton.MenuItem.Description,
      standardImageURL: null,
      thumbnailImageURL: null,
      seoDescription: null,
      minQuantity: 1,
    } as Product;
  }

  leadThroughToOptionGroup(leadThrough: ItwercsLeadThrough, Item?: ItwercsLeadThroughItem): OptionGroup {
    return {
      optionGroupID: String(leadThrough.Id),
      name: leadThrough.Name,
      description: null,
      maxAllowed: leadThrough.Max,
      minRequired: leadThrough.Min,
      options: leadThrough.Items ? leadThrough.Items.map(item => this.leadThroughItemsToOptions(item)) : [],
      mandatorySelection: leadThrough.Min > 0,
      optionGroupParent: Item ? String(Item.LTGroupID) : null,
    } as OptionGroup;
  }

  leadThroughItemsToOptions(item: ItwercsLeadThroughItem): Option {
    return {
      optionID: item.MenuItemID.toString(),
      brandOptionID: item.MenuItemID.toString(),
      name: item.Text,
      addedCents: item.Price * 100,
      isDefault: item.AutoSelectQty === 1,
      isSelected: item.QTY > 0,
      optionGroups: item.LeadThroughs ? item.LeadThroughs.map(group => this.leadThroughToOptionGroup(group, item)) : null,
      nutritionInfo: null,
      standardImageURL: null,
      thumbnailImageURL: null,
      whenSelected: null,
      modifierCategoryID: item.LTGroupID,
      parentOptionGroupID: String(item.LTGroupID),
    } as Option;
  }

  itwercsOrderToOrder(order: ItwercsOrder, location: Location): Order {
    return {
      orderID: order.Id.toString(),
      orderReference: order.ReferenceId,
      taxCents: Number((order.TaxTotal * 100).toFixed(0)),
      subTotalCents: Number((order.SubTotal * 100).toFixed(0)),
      tipCents: Number(((order.PaymentTotal - order.GrandTotal) * 100).toFixed(0)),
      totalCents: Number((order.GrandTotal * 100).toFixed(0)),
      orderPlacedTimestamp: moment.utc(order.SubmittedOnUTC).local().toDate(),
      appliedCouponCents: Number((order.DiscountTotal * 100).toFixed(0)),
      handoffType: 5,
      orderReadyTimestamp: moment.utc(order.PickUpOnUTC).local().toDate(),
      location,
      orderStatus: this.mapOrderStatusIntToString(order.Status),
      items: this.populateOrderItemsFromItwercsOrder(order.Items),
      deliveryAddress: null,
      earliestReadyTimestamp: moment.utc(order.PickUpOnUTC).local().toDate(),
      isASAP: order.IsASAP,
      curbsideCustomFields: null,
      customFields: null,
      dineinCustomFields: null,
      appliedCouponCode: null,
      appliedReward: null,
      appliedRewards: [],
      balanceCents: Number(((order.GrandTotal - order.DiscountTotal) * 100).toFixed(0)),
      isGroup: null,
      paidCents: Number((order.PaymentTotal * 100).toFixed(0)),
      specialInstructions: null,
      tableNumber: null,
      fees: [],
      deliveryFee: null,
      canTip: true,
      arrivalstatus: null,
      isEditable: false,
      supportedPaymentTypes: [PaymentTypes.creditCard],
      requiresFullBillingAddress: false,
      donationType: [],
      donationsTotal: 0,
    } as Order;
  }

  populateOrderItemsFromItwercsOrder(items: ItwercsOrderItem[]): OrderItem[] {
    const orderItems: OrderItem[] = [];
    items.forEach(item => {
      if (item.ParentNumber === 0) {
        orderItems[item.ItemNumber] = this.itwercsOrderItemToOrderItem(item);
      } else {
        if (item.MenuItemId === 0) {
          if (item.MenuItemDesc.includes('Name:')) {
            orderItems[item.ParentNumber].guestName = item.MenuItemDesc.substring(5);
          } else if (item.IsInstruction) {
            orderItems[item.ParentNumber].instructions = item.MenuItemDesc;
          } else {
            orderItems[item.ParentNumber].options.push(this.itwercsOrderItemToOrderItemModifier(item));
          }
        } else {
          orderItems[item.ParentNumber].options.push(this.itwercsOrderItemToOrderItemModifier(item));
        }
      }
    });
    const reassignedKeys: OrderItem[] = [];
    orderItems.forEach(item => {
      reassignedKeys.push(item);
    });
    return reassignedKeys;
  }

  itwercsOrderItemToOrderItemModifier(item: ItwercsOrderItem): OrderItemModifier {
    return {
      optionID: String(item.MenuItemId),
      brandOptionID: String(item.MenuItemId),
      optionGroupID: String(item.ParentNumber),
      name: item.MenuItemDesc,
      addedCents: item.Amount * 100,
      nutritionInfo: null,
      quantity: item.Qty,
      modifierCategoryID: item.MenuItemId, // this is only for NovaDine
      indentLevel: 0,
    };
  }

  itwercsOrderItemToOrderItem(item: ItwercsOrderItem): OrderItem {
    return {
      categoryID: item.ParentNumber.toString(),
      guestName: null,
      instructions: item.MenuItemId === 0 ? item.MenuItemDesc : null,
      longDesc: null,
      menuID: item.MenuItemId.toString(),
      name: item.MenuItemId !== 0 ? item.MenuItemDesc : null,
      optionGroups: null,
      orderItemID: this.generateGUID(), // ITWERCS does not provide specific IDs for individual items
      totalCents: item.Amount * 100,
      productID: item.MenuItemId.toString(),
      quantity: item.Qty,
      shortDesc: item.MenuItemId !== 0 ? item.MenuItemDesc : null,
      standardImageURL: null,
      thumbnailImageURL: null,
      userID: null,
      options: [], // TODO: Map Leadthroughs to OrderItemModifiers[]
      nameSlug: null,
      isAlcohol: false,
      seoDescription: null,
      showAsModal: null,
    } as OrderItem;
  }

  guestToUser(guest: Guest, guestID?: string): User {
    return {
      userID: guestID ? guestID : guest.Id,
      userAsBarcode: null,
      userAsQrCode: null,

      orderingToken: null,
      orderingTokenProvider: null,

      firstName: guest.FirstName,
      lastName: guest.LastName,
      email: guest.Email,
      phoneNumber: guest.Phone,
      addresses: this.mapGuestAddressToUserAddresses(guest.Address1, guest.Address2, guest.City, guest.State, guest.Zip),

      emailOptIn: guest.Dtl.AllowEmail,
      sMSOptIn: guest.Dtl.AllowText,
      loyaltyOptIn: null,
      pushOptIn: null,

      isGuest: false,
      company: null,
    } as User;
  }

  createGuestToUser(guest: CreateGuestRequestGuest, guestID: string): User {
    return {
      userID: guestID,
      userAsBarcode: null,
      userAsQrCode: null,

      orderingToken: null,
      orderingTokenProvider: null,

      firstName: guest.FirstName,
      lastName: guest.LastName,
      email: guest.Email,
      phoneNumber: guest.Phone,
      addresses: this.mapGuestAddressToUserAddresses(guest.Address1, guest.Address2, guest.City, guest.State, guest.Zip),

      emailOptIn: guest.Dtl.AllowEmail,
      sMSOptIn: guest.Dtl.AllowText,
      loyaltyOptIn: null,
      pushOptIn: null,

      isGuest: false,
      company: null,
    } as User;
  }

  newOrder(location: Location, handoff: HandoffType): Order {
    return {
      orderID: '',
      orderReference: '',
      taxCents: 0,
      subTotalCents: 0,
      tipCents: 0,
      totalCents: 0,
      orderPlacedTimestamp: null,
      appliedCouponCents: 0,
      handoffType: isNaN(handoff) ? 0 : handoff,
      orderReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
      location,
      orderStatus: null,
      items: [],
      deliveryAddress: null,
      earliestReadyTimestamp: moment().add(location.orderLeadTime, 'minutes').toDate(),
      isASAP: true,
      customFields: null,
      curbsideCustomFields: null,
      dineinCustomFields: null,
      appliedCouponCode: null,
      appliedReward: null,
      appliedRewards: [],
      balanceCents: 0,
      isGroup: null,
      paidCents: 0,
      specialInstructions: null,
      tableNumber: sessionStorage.getItem('tablenumber'),
      fees: [],
      deliveryFee: null,
      canTip: true,
      arrivalstatus: null,
      isEditable: false,
      supportedPaymentTypes: [PaymentTypes.creditCard],
      requiresFullBillingAddress: false,
      donationType: [],
      donationsTotal: 0,
    } as Order;
  }

  orderToItwercsSubmitOrder(order: Order, destID: number, user?: User): OrderSubmitCalculateRequest {
    const mode = sessionStorage.getItem('mode');
    const interfaces: OrderInterface[] = [];
    if (order.specialInstructions) {
      interfaces.push(this.specialInstructionsToInterface(order.specialInstructions));
    }
    if (order.tableNumber) {
      interfaces.push(this.tableNumberToInterface('Table: ' + order.tableNumber));
    }
    interfaces.forEach((item, index) => {
      interfaces[index].ItemNo = index + 1;
    });
    let orderUser;
    if (user && mode !== 'tableside') {
      orderUser = this.userToOrderSubmitGuest(user);
    } else {
      orderUser = this.genericUser();
    }
    if (mode === 'tableside') {
      orderUser.FirstName = sessionStorage.getItem('tablesideGuest');
    }
    const newOrder = {
      SiteId: parseInt(order.location.locationID, 10),
      Guest: orderUser,
      // tslint:disable-next-line:max-line-length
      PickUpOnUTC: order.isASAP
        ? moment().utc().add(order.location.orderLeadTime, 'minutes').toDate()
        : moment(order.orderReadyTimestamp).utc().toDate(),
      IsASAP: order.isASAP,
      DestinationId: isNaN(destID) ? 14 : destID,
      TableId: order.tableNumber ? Number(order.tableNumber) : 0,
      ReferenceId: order.orderReference,
      Items: order.items.map(item => this.deOrderItemToItwercsOrderItem(item, destID)),
      Payments: [],
      InterfaceItems: interfaces,
      CampGuid: '',
    };

    newOrder.Items[0] = {
      ...newOrder.Items[0],
      ItemNumber: 0,
    };
    return newOrder as OrderSubmitCalculateRequest;
  }

  specialInstructionsToInterface(instructions: string): OrderInterface {
    return {
      ItemNo: 0,
      RecordType: 'SI',
      InterfaceType: 7,
      Description: instructions,
      Seat: 1,
      ParentItemNo: 0,
      SubItems: [],
      RingTime: moment().utc().format(),
    } as OrderInterface;
  }

  tableNumberToInterface(tableNumber: string): OrderInterface {
    return {
      ItemNo: 0,
      RecordType: 'SI',
      InterfaceType: 7,
      Description: tableNumber,
      Seat: 1,
      ParentItemNo: 0,
      SubItems: [],
      RingTime: moment().utc().format(),
    } as OrderInterface;
  }

  deOrderItemToItwercsOrderItem(item: OrderItem, handoff: HandoffType): OrderSubmitCalculateRequestOrderItem {
    return {
      MenuItemId: Number(item.productID),
      MenuItemDesc: item.name,
      Amount: item.baseCents / 100,
      OrderTimeUTC: moment().utc().format(),
      DestinationID: handoff,
      SubItems: this.populateSubItems(item, handoff),
      Qty: item.quantity,
    } as OrderSubmitCalculateRequestOrderItem;
  }

  populateSubItems(item: OrderItem, handoff): OrderSubmitCalculateRequestOrderItem[] {
    const subItems = [];
    if (item.guestName) {
      subItems.push(this.guestNameToSubItem(item.guestName, handoff));
    }
    if (item.instructions) {
      subItems.push(this.specialInstructionsToSubItem(item.instructions, handoff));
    }
    item.options.forEach(modifier => {
      subItems.push(this.optionItemToItwercsOrderItem(modifier, handoff));
    });
    return subItems;
  }

  optionItemToItwercsOrderItem(item: OrderItemModifier, handoff): OrderSubmitCalculateRequestOrderItem {
    return {
      MenuItemId: Number(item.optionID),
      MenuItemDesc: item.name,
      Amount: Number((item.addedCents / 100).toFixed(2)),
      OrderTimeUTC: moment().utc().format(),
      DestinationID: handoff,
      Qty: 1,
    } as OrderSubmitCalculateRequestOrderItem;
  }

  guestNameToSubItem(name: string, handoff): OrderSubmitCalculateRequestOrderItem {
    return {
      MenuItemId: 0,
      MenuItemDesc: `Name: ${name}`,
      Amount: 0,
      OrderTimeUTC: moment().utc().format(),
      DestinationID: handoff,
      Qty: 1,
    } as OrderSubmitCalculateRequestOrderItem;
  }

  specialInstructionsToSubItem(instructions: string, handoff): OrderSubmitCalculateRequestOrderItem {
    return {
      MenuItemId: 0,
      MenuItemDesc: instructions,
      Amount: 0,
      OrderTimeUTC: moment().utc().format(),
      DestinationID: handoff,
      IsInstruction: true,
      Qty: 1,
    } as OrderSubmitCalculateRequestOrderItem;
  }

  screenPagesToMenu(siteID: number, pages: ScreenPage[]): Menu {
    const newPages: ScreenPage[] = [];
    pages.forEach(page => {
      if (page.Buttons.length > 0) {
        newPages.push(page);
      }
    });
    return {
      menuID: siteID.toString(),
      categories: newPages.map(page => this.screenPageToCategory(page)),
      singleUseProducts: null,
    };
  }

  cardDetailsToDEUser(card: CardDetails): User {
    return {
      userID: null,
      userAsBarcode: null,
      userAsQrCode: null,

      orderingToken: null,
      orderingTokenProvider: null,

      firstName: card.firstName,
      lastName: card.lastName,
      email: card.emailAddress,
      phoneNumber: card.phoneNumber,
      addresses: [],

      emailOptIn: false,
      sMSOptIn: false,
      loyaltyOptIn: false,
      pushOptIn: false,

      isGuest: true,
      company: card.company,
    } as User;
  }

  userToOrderSubmitGuest(user: User): OrderSubmitCalculateGuest {
    return {
      Id: user.userID,
      FirstName: user.firstName,
      LastName: user.lastName,
      Address1: user.addresses[0] ? user.addresses[0].address1 : null,
      Address2: user.addresses[0] ? user.addresses[0].address2 : null,
      City: user.addresses[0] ? user.addresses[0].city : null,
      State: user.addresses[0] ? user.addresses[0].state : null,
      Zip: user.addresses[0] ? user.addresses[0].zipCode : null,
      Phone: user.phoneNumber,
      Email: user.email,
      Dtl: {
        ID: 0,
        GuestID: null,
        EntID: 0,
        OrgID: 0,
        Loyalty: user.loyaltyOptIn,
        AllowText: user.sMSOptIn,
        AllowEmail: user.emailOptIn,
        PerferredComm: 0,
        FavoriteSiteID: 0,
        FavoriteSite: null,
        CreatedOn: moment().utc().format(),
      } as GuestDetail,
    } as OrderSubmitCalculateGuest;
  }

  itwercsOrderItemToProduct(item: ItwercsOrderItem): Product {
    return {
      allergenInfo: null,
      canModify: null,
      categoryID: null,
      isAvailable: true,
      longDesc: null,
      menuID: item.MenuItemId.toString(),
      name: item.MenuItemDesc,
      nutritionInfo: null,
      optionGroups: null, // TODO: ItwercsOrderItem does not have option groups...
      priceCents: item.Amount * 100,
      productID: item.MenuItemId.toString(),
      requiresModification: null,
      shortDesc: item.MenuItemDesc,
      standardImageURL: null,
      thumbnailImageURL: null,
      minQuantity: 1,
    } as Product;
  }

  menuItemToProduct(item: MenuItem): Product {
    return {
      allergenInfo: null,
      canModify: item.LeadThroughs.length > 0,
      categoryID: null,
      isAvailable: item.Available,
      longDesc: item.Description,
      menuID: null,
      name: item.Name,
      nameSlug: null,
      nutritionInfo: null,
      optionGroups: item.LeadThroughs.map(leadThrough => this.leadThroughToOptionGroup(leadThrough)),
      priceCents: item.Price <= 0 ? 0 : item.Price * 100,
      productID: item.Id.toString(),
      requiresModification: item.LeadThroughs.length > 0,
      shortDesc: item.Description,
      standardImageURL: null,
      thumbnailImageURL: null,
      currentlyAvailable: item.Available,
      minQuantity: 1,
    } as Product;
  }

  cardDetailsToCreditCard(card: CardDetails): CreditCard {
    return {
      ccNumber: card.cardNumber,
      cardExp: card.expirationMonth + card.expirationYear,
      cardholderName: `${card.firstName} ${card.lastName}`,
      ccv: Number(card.cvv),
      zipCode: Number(card.billingAddress.zipCode),
    } as CreditCard;
  }

  paymentResponseToOrderPayment(payment: PaymentResponse): OrderPayment {
    return {
      RecType: 'MP',
      TenderId: this.brandToPaymentType(payment.cardBrand),
      TenderName: payment.cardBrand,
      MaskedCC: payment.account.substr(-4, 4),
      ExpDate: payment.expiration, // February 2025 = 0225 – cannot exceed 4 character length
      TipAmount: Number(payment.tipAmount), // Contains a tip amount
      Amount: payment.authAmount - payment.tipAmount, // Amount of payment
      AuthCode: payment.authCode, // Auth code of transaction - cannot exceed 10 character length
      PnRef: payment.refNo,
      Token: payment.token,
    } as OrderPayment;
  }

  private mapGuestAddressToUserAddresses(address1: string, address2: string, city: string, state: string, zip: string): Address[] {
    const addresses = [];
    const address = {
      address1,
      address2,
      city,
      state,
      zipCode: zip,
      latitude: null,
      longitude: null,
    } as Address;
    addresses.push(address);
    return addresses;
  }

  private mapOrderStatusIntToString(code: string | number): string {
    if (isNaN(code as any)) {
      return String(code);
    } else {
      switch (code) {
        case 0:
          return 'Unknown';
        case 1:
          return 'Pending'; // Order accepted and awaiting transfer to store
        case 2:
          return 'Sent'; // Order sent to store
        case 3:
          return 'Fired'; // Check started and order fired in the kitchen and is being prepared
        case 4:
          return 'Completed'; // Order marked as completed by kitchen
        case 5:
          return 'Closed'; // Check Closed.  This typically means the order has been picked up
        case 10:
          return 'Invalid Order'; // Order not accepted
        case 11:
          return 'Invalid Site'; // Site id is not one within the concept associated with the token
        case 12:
          return 'Site Closed'; // Site is marked as closed
      }
    }
  }

  private generateCalendars(site: ItwercsSite): TimeFrame[] {
    const times = [];
    const today = moment();
    switch (today.format('d')) {
      case '0':
        times.push({
          start: moment(site.OOSunStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOSunEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '1':
        times.push({
          start: moment(site.OOMonStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOMonEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '2':
        times.push({
          start: moment(site.OOTueStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOTueEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '3':
        times.push({
          start: moment(site.OOWedStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOWedEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '4':
        times.push({
          start: moment(site.OOThuStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOThuEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '5':
        times.push({
          start: moment(site.OOFriStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOFriEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOSatStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOSatEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
      case '6':
        times.push({
          start: moment(site.OOSatStart).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
          end: moment(site.OOSatEnd).year(today.year()).dayOfYear(today.dayOfYear()).toDate(),
        });
        times.push({
          start: moment(site.OOSunStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
          end: moment(site.OOSunEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 1)
            .toDate(),
        });
        times.push({
          start: moment(site.OOMonStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
          end: moment(site.OOMonEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 2)
            .toDate(),
        });
        times.push({
          start: moment(site.OOTueStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
          end: moment(site.OOTueEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 3)
            .toDate(),
        });
        times.push({
          start: moment(site.OOWedStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
          end: moment(site.OOWedEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 4)
            .toDate(),
        });
        times.push({
          start: moment(site.OOThuStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
          end: moment(site.OOThuEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 5)
            .toDate(),
        });
        times.push({
          start: moment(site.OOFriStart)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
          end: moment(site.OOFriEnd)
            .year(today.year())
            .dayOfYear(today.dayOfYear() + 6)
            .toDate(),
        });
        return times;
    }
  }

  private determineLocationOpen(site: ItwercsSite): boolean {
    const now = moment();
    let start;
    let end;
    switch (now.format('d')) {
      case '0':
        start = moment(site.OOSunStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOSunEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '1':
        start = moment(site.OOMonStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOMonEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '2':
        start = moment(site.OOTueStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOTueEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '3':
        start = moment(site.OOWedStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOWedEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '4':
        start = moment(site.OOThuStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOThuEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '5':
        start = moment(site.OOFriStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOFriEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
      case '6':
        start = moment(site.OOSatStart).year(now.year()).dayOfYear(now.dayOfYear());
        end = moment(site.OOSatEnd).year(now.year()).dayOfYear(now.dayOfYear());
        return now.isAfter(start) && now.isBefore(end);
    }
  }

  generateGUID() {
    // tslint:disable-next-line:max-line-length
    return (
      this.S4() +
      this.S4() +
      '-' +
      this.S4() +
      '-4' +
      this.S4().substr(0, 3) +
      '-' +
      this.S4() +
      '-' +
      this.S4() +
      this.S4() +
      this.S4()
    ).toLowerCase();
  }

  private S4() {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  private genericUser(): OrderSubmitCalculateGuest {
    return {
      Id: '0', // Guest Id; can be 0 for guests not created
      FirstName: 'Guest', // First Name of the guest - cannot exceed 50 character length
      Phone: '000-000-0000', // Phone Number of the guest; Format is ###-###-#### - cannot exceed 15 character length
      Email: 'test@email.com', // Email address of the guest - cannot exceed 100 character length
      Dtl: {
        ID: 0,
        GuestID: '0',
        EntID: 0,
        OrgID: 0,
        Loyalty: false,
        AllowText: false,
        AllowEmail: false,
        PerferredComm: 0,
        FavoriteSiteID: 0,
        FavoriteSite: '',
        CreatedOn: moment().utc().format(),
      } as GuestDetail, // This field is “required” however the data in it can be defaulted
    } as OrderSubmitCalculateGuest;
  }

  handoffTypeToHandoffType(handoffType: HandoffType): 'delivery' | 'dispatch' | 'curbside' | 'pickup' | 'drivethru' | 'dinein' {
    switch (handoffType) {
      case HandoffType.delivery:
        return 'delivery';
      case HandoffType.dispatch:
        return 'dispatch';
      case HandoffType.curbside:
        return 'curbside';
      case HandoffType.pickup:
        return 'pickup';
      case HandoffType.driveThru:
        return 'drivethru';
      case HandoffType.dineIn:
        return 'dinein';
      default:
        return 'pickup';
    }
  }

  handoffTypeToHandoffTypeReverse(handoffType: string): HandoffType {
    switch (handoffType) {
      case 'delivery':
        return HandoffType.delivery;
      case 'dispatch':
        return HandoffType.dispatch;
      case 'curbside':
        return HandoffType.curbside;
      case 'pickup':
        return HandoffType.pickup;
      case 'drivethru':
        return HandoffType.driveThru;
      case 'dinein':
        return HandoffType.dineIn;
      default:
        return HandoffType.pickup;
    }
  }

  private brandToPaymentType(brand: string) {
    switch (brand.toUpperCase()) {
      case 'AMEX':
        return PaymentTypes.amex;
      case 'DCLB':
        return PaymentTypes.dinersClub;
      case 'DCVR':
        return PaymentTypes.discover;
      case 'M/C':
        return PaymentTypes.mastercard;
      case 'VISA':
        return PaymentTypes.visa;
      default:
        return PaymentTypes.creditCard;
    }
  }
}
