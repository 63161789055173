// src/app/components/activity-section/activity-section.component.ts

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription, of, firstValueFrom } from 'rxjs';
import { HistoryEvent } from '../../../../interfaces/history-event.interface';
import { SetRouteBack, UpdateTitle } from '../../../../store/actions/app.actions';
import { SetLoyaltyActivity } from '../../../../store/actions/user.actions';
import { distinctUntilChanged, filter, catchError, finalize, map } from 'rxjs/operators';
import { MetaService } from '../../../../services/meta.service';
import { NavigationService } from '@modules/navigation/services';
import { VendorSetup } from '../../../../vendors/directus/interfaces/vendor.interface';
import { Order } from '../../../../interfaces/order.interface';
import { User } from '../../../../interfaces/user.interface';
import { LoyaltySection, MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { LoyaltyActivity } from '../../../../vendors/personica/interfaces/get-activity-response.interface';

@Component({
  selector: 'app-activity-section',
  templateUrl: './activity-section.component.html',
  styleUrls: ['activity-section.component.scss'],
})
export class ActivitySectionComponent implements OnInit, OnDestroy {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.activity) loyaltyActivity$: Observable<HistoryEvent[]>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.activity) activity$: Observable<HistoryEvent[]>;

  @Input() section: LoyaltySection;
  @Input() activity: HistoryEvent[] = [];
  @Input() isDashboard = false;

  @Output() orderNow = new EventEmitter<void>();

  // Page details for SEO
  title = 'Messages';
  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayActivities: any[];
  displayOrder: Order;
  displayUser: User;
  isLoading = false;
  locationsRoute = this.navigation.getLocationsPageSlug();
  activityRoute = this.navigation.getActivityPageSlug();
  private subs: Subscription[] = [];


  constructor(
    // private pageService: ActivityPageService,
    // private navbarService: NavBarService,
    private store: Store,
    private meta: MetaService,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.store
      .dispatch(new SetLoyaltyActivity())
      .toPromise()
      .then(() => (this.isLoading = false));
    // Navbar service data
    this.subs.push(
      this.vendorSetup$.subscribe(vendorSetup => {
        if (vendorSetup) {
          this.displayVendorSetup = vendorSetup;
        }
      })
    );
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        if (user) {
          this.displayUser = user;
        } else {
          this.displayUser = null;
        }
      })
    );
    this.subs.push(
      combineLatest([
        this.mainSettings$,
        this.loyaltyActivity$.pipe(
          filter(a => a !== null),
          distinctUntilChanged((prev, curr) => prev.length === curr.length)
        ),
      ]).subscribe(([mainSettings, activity]) => {
        if (activity && mainSettings) {
          this.displayActivities = activity;
          mainSettings.transaction_type_tags.forEach((tag: string) => {
            this.displayActivities = this.displayActivities.filter((event: any) => event.type.toLowerCase() !== tag);
          });
        }
      })
    );
  }

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }
}
